import cards from '../data/tarots.json'
import decks from '../data/decks.json'

export const cardList = Object.values(cards)

export function getDeckList() {
    return [
        ...Object.values(decks)
    ]
}

export function getDeckById(id) {
    return {
        ...decks[String(id)]
    };
}

export function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function getRandomCard(deckId) {
    const selectedDeck = decks[deckId]; // TODO: lấy từ localstorage
    const randomCard   = cards[getRandomInt(1, 78)] // TODO: lấy random thêm mặt xuôi hoặc ngược

    return {
        ...randomCard,
        image: selectedDeck.cards[randomCard.id].card_image
    };
}

export function getCardById(id, deckId) {
    return {
        ...cards[id],
        image: decks[deckId].cards[id].card_image
    }
}

export function getCardsByType(type, deckId) {
    return [
        ...Object.values(cards).filter((item) => {
            return item.type === type;
        }).map(item => getCardById(item.id, deckId))
    ]
}
