import React from 'react';
import classNames from "classnames";
import { motion } from "framer-motion";
import { useDraggable } from "@dnd-kit/core";

const Card = ({ className, card, ...props }) => {
    const { isDragging, attributes, listeners, setNodeRef, transform} = useDraggable({
        id: `draggable-${props.id}`,
        data: card
    });
    const style = transform ? {
        transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
        visibility: isDragging ? 'hidden' : 'visible'
    } : undefined;

    return (
        <motion.div
            layoutId={card?.id}
            transition={{ type: "tween" }}
            className={'card-item'}
            exit="hidden"
            style={{ ...style, width: props.width, height: props.height}}>
            <div ref={setNodeRef} {...listeners} {...attributes} style={{ ...style, width: props.width, height: props.height}}>
                <img onClick={props.onClick} src={props.backface ? props.backface : '/imgs/BackFaceCard.png'} className={classNames('rounded-md', { 'rotate-3': props.rotate })} alt='Back face card'/>
            </div>
        </motion.div>
    );
};

export default Card;