import {Outlet, useLocation} from "react-router-dom";
import {useEffect} from "react";

export default function DefaultLayout() {
    const location = useLocation()

    useEffect(() => {
        window.parent.postMessage(
            {
                type: 'mtb/location',
                payload: {
                    pathname: location.pathname,
                    search: location.search
                }
            },
            '*'
        )
    }, [location]);

    useEffect(() => {
        const layout = document.querySelector('.default-layout')
        const interval = setInterval(() => {
            window.parent.postMessage(
                {
                    type: 'mtb/frameResized',
                    payload: {
                        width: parseInt(String(layout?.clientWidth)),
                        height: parseInt(String(layout?.clientHeight))
                    }
                },
                '*'
            )
        }, 300)

        return () => clearInterval(interval)
    }, [])

    return (
        <div className="default-layout">
            <Outlet />
        </div>
    )
}