import {createContext, useContext, useEffect, useState} from "react";
import {getDeckList} from "../helpers/card";
import {nanoid} from "nanoid";
import {getSpreadList, saveSpread, useSpreadList} from "../helpers/firebase";
import {useAppContext} from "./AppContext";

const SpreadContext = createContext()
const initialDecks = getDeckList()

export const useSpreadContext = () => useContext(SpreadContext)

const saveStorage = (name, values) => {
    if (values) {
        localStorage.setItem(name, JSON.stringify(values))
    }

    return values
}

const getFromStorage = (name, defaultValues) => {
    try {
        const values = JSON.parse(localStorage.getItem(name));
        return values || saveStorage(name, defaultValues)
    } catch {
        return saveStorage(name, defaultValues)
    }
}

export const SpreadContextProvider = ({ children }) => {
    const { user } = useAppContext();
    const { spreadList, fetchSpreads } = useSpreadList(user);
    const [decks] = useState(initialDecks)
    const [spread, setSpread] = useState(getFromStorage('_spread', {}))

    const setType = (type) => {
        setSpread((prev) => ({
            ...prev,
            type
        }))
    }

    const setDeck = (deck) => {
        setSpread({
            deck
        })
    }

    const setCard = (card) => {
        setSpread((prev) => ({
            ...prev,
            card: { ...card }
        }))
    }

    const commitIntentions = (intentions) => {
        setSpread((prev) => ({
            ...prev,
            intentions
        }))
    }

    const commitReflections = async (reflections) => {
        await saveSpread({
            ...spread,
            reflections
        })

        fetchSpreads()
        makeSpread(spread.type)
    }

    const makeSpread = (type) => {
        setSpread((prev) => ({
            ...prev,
            id: nanoid(),
            type,
            card: null,
            intentions: [],
            reflections: '',
            date: new Date(),
            userId: user.firebaseId
        }))
    }

    useEffect(() => {
        if (spread) {
            saveStorage('_spread', spread)
        }
    }, [spread]);

    if (user.id === undefined || spreadList === null) {
        return <div>Loading...</div>
    }

    return (
        <SpreadContext.Provider
            value={{
                decks,
                spread,
                setSpread,
                spreadList,
                makeSpread,
                setType,
                setDeck,
                setCard,
                commitIntentions,
                commitReflections
            }}>
            {children}
        </SpreadContext.Provider>
    )
}