export const getSpreadName = (spreadType) => {
    return {
        daily: 'Daily',
        single: 'Single',
        three: '3 Cards'
    }[spreadType]
}

export const getSpreadDescription = (spreadType) => {
    return {
        daily: `Welcome to Daily Tarot. The card you choose may illuminate some facet of your day, whether you go into it with a clear and open mind or an intention. If you'd like, keep a question in mind as you draw. Remember the cards are a starting point for your awareness, insight, and inspiration.`,
        single: `Single Card pulls are great when you\'re first starting to familiarize yourself with your deck. You could, for example, pull one card daily and journal about it`,
        three: `The Past-Present-Future Spread is the most common 3 card spread that focuses on past, present, and future insights. This spread provides valuable insights into the decisions and events that have shaped your past, the current situation you are in, and what may lie ahead of you in the future`
    }[spreadType]
}