import React from 'react';

const ModalShare = ({ isOpen, closeModal, children }) => {
  if (!isOpen) return null;

  return (
    <div className="modal" onClick={closeModal}>
        <div className="modal-content fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-grad-black w-[375px] z-[4]">
            {children}
        </div>
    </div>
  );
};

export default ModalShare;
