import '@fontsource/trykker';
import '@fontsource-variable/space-grotesk';
import '@fontsource/alata';
import '@fontsource/paytone-one';
import 'swiper/css/bundle';
import 'react-toastify/dist/ReactToastify.css';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import './index.css';

import React from 'react';
import ReactDOM from 'react-dom/client';
import routes from './routes'
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { SpreadContextProvider } from "./contexts/SpreadContext";
import { ToastContainer } from "react-toastify";
import {AppContextProvider} from "./contexts/AppContext";
import {QueryClientProvider, QueryClient} from "@tanstack/react-query";

const router = createBrowserRouter(routes)
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <AppContextProvider>
        <QueryClientProvider client={new QueryClient()}>
            <SpreadContextProvider>
                <ToastContainer />
                <RouterProvider router={router} />
            </SpreadContextProvider>
        </QueryClientProvider>
    </AppContextProvider>
);
