import React, {useEffect, useState} from "react";
import {Link, useNavigate, useParams} from 'react-router-dom';
import {cardList, getCardById} from "../helpers/card";
import {AnimatePresence, motion} from 'framer-motion'

import {getSpreadName} from "../helpers/spread";
import {useSpreadContext} from "../contexts/SpreadContext";
import classNames from "classnames";
import Card from "../components/elements/Card";
import ReactSlidingPane from "react-sliding-pane";
import {SpreadDetail} from "../components/elements/SpreadDetail";
import {DndContext, DragOverlay, TouchSensor, useSensor, useSensors} from "@dnd-kit/core";
import Droppable from "../components/elements/Droppable";

const getOffhandCards = (count) => {
    return [...Array(count).keys()]
}

const PickCard = () => {
    const { spreadType } = useParams()
    const { spread, setCard } = useSpreadContext()

    const [cardCount, setCardCount] = useState(1)
    const [selectedCards, setSelectedCards] = useState([])
    const [cards, setCards] = useState([...cardList].sort(() =>  Math.random() - 0.5));
    const [aboutSpread, setAboutSpread] = useState()
    const [activeId, setActiveId] = useState(null);
    const [isDropped, setIsDropped] = useState(false);

    const navigate = useNavigate()
    const sensors = useSensors(
        useSensor(TouchSensor)
    );


    const handleShowCard = () => {
        setCard([...selectedCards])
        navigate('../reflection', {
            relative: 'path'
        })
    }

    const selectCard = (card) => {
        if (selectedCards.length < cardCount) {
            setSelectedCards((prev) => [
                ...prev,
                getCardById(card.id, spread.deck.id)
            ])

            setCards(() => cards.filter(card => selectedCards.findIndex(selected => selected.id === card.id) === -1))
        }
    }

    function handleDragEnd(event) {
        setActiveId(null);

        if (event.over) {
            selectCard(event.active.data.current);
            setIsDropped(true);
        }
    }

    useEffect(() => {
        if (spreadType) {
            setCardCount(() => {
                return {
                    daily: 1,
                    single: 1,
                    three: 3
                }[spreadType]
            })
        }
    }, [spreadType]);
    
    return (
        <DndContext sensors={sensors} onDragStart={(e) => setActiveId(e.active.id)} onDragEnd={handleDragEnd}>
            <DragOverlay>{activeId && (<Card rotate={false} backface={spread.deck.background} />)}</DragOverlay>
            <div className="main-menu bg-black text-white pb-8">
                <div className="p-4 pb-0"  >
                    <div className="grid gap-8">
                        <h3 className="text-2xl uppercase text-center">Pick a Card</h3>
                        <motion.div className="flex space-x-2.5 justify-center">
                            {getOffhandCards(cardCount).map(index => (
                                <motion.div key={`slot-${index}`} className={classNames("max-w-[138px] w-full aspect-[106/180] rounded-lg")}>
                                    <Droppable id={index}>
                                        {selectedCards[index] && (<Card card={selectedCards[index]} backface={spread.deck.background} rotate={false} />)}
                                    </Droppable>
                                </motion.div>
                            ))}
                        </motion.div>
                        <div className='text-[#ffffff80] text-center font-futura'>Drag a card on to the empty spot.</div>
                    </div>
                </div>
                <div className={classNames('card-list mt-8 transition-all', { 'opacity-40': cardCount === selectedCards.length })}>
                    <AnimatePresence>
                        {cards.map((item) => (
                            <Card
                                onClick={() => selectCard(item)}
                                card={item}
                                backface={spread.deck.background}
                                key={item.id}
                                id={item.id}
                                width="138px"
                                height="235px"
                                rotate={true}
                            />
                        ))}
                    </AnimatePresence>
                </div>

                <div className="mt-4">
                    <button type="button" onClick={() => setAboutSpread(() => true)} className="block mx-auto border-0 text-[#FEB710] underline mt-4 text-center block">
                        About {getSpreadName(spreadType)} Spread
                    </button>

                    <button onClick={handleShowCard} disabled={selectedCards.length !== cardCount} className={'btn btn-primary mt-8 w-full mb-4 disabled:opacity-40'}>Show card</button>
                    <Link to={"/dashboard"} className="btn btn-outlined bg-transparent">Cancel</Link>
                </div>
            </div>
            <ReactSlidingPane
                hideHeader={true}
                isOpen={!!aboutSpread}
                onRequestClose={() => setAboutSpread(() => null)}
                className="p-0 bg-[#212121]"
                width={'100%'}>
                <SpreadDetail type={spreadType} onRequestClose={() => setAboutSpread(() => null)} />
            </ReactSlidingPane>
        </DndContext>
    );
};

export default PickCard;