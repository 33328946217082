import React from 'react';

const IntentionTile = (props) => {
    return (
        <div className='bg-[#393939] rounded-[10px] p-3 text-xl font-medium text-white flex flex-col items-center justify-center'>
            <h3 className='text-base text-[#9c9c9c]'>Card: {props.card.name}</h3>
            <h3>{props.userIntention}</h3>
        </div>
    );
};

export default IntentionTile;
