import {useDroppable} from '@dnd-kit/core';
import classNames from "classnames";

export default function Droppable(props) {
    const {isOver, setNodeRef} = useDroppable({
        id: `droppable-${props.id}`,
    });

    const style = {
        borderColor: isOver ? '#FEB710' : undefined,
    };

    return (
        <div ref={setNodeRef} style={style} className={classNames("max-w-[138px] w-full aspect-[106/180] border border-dashed mx-auto border-[#a9a9a980] bg-[#000] rounded-lg")}>
            {props.children}
        </div>
    );
}