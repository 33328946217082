import {Outlet} from "react-router-dom";
import Layout from './components/layouts/DefaultLayout'
import Dashboard from './pages/Dashboard';
import PickDeck from './pages/PickDeck';
import Splash from './pages/Splash';
import PickCard from './pages/PickCard';
import AboutCard from './pages/AboutCard';
import AboutDeck from './pages/AboutDeck';
import AboutSpread from "./pages/AboutSpread";
import IntentionSpread from "./pages/IntentionSpread";
import Reflection from "./pages/Reflection";
import {Reflections} from "./components/elements/Reflections";

const routes = [{
    path: '/',
    element: <Layout />,
    children: [
        // Main screen
        { index: true, element: <Splash /> },
        {
            path: 'deck',
            element: <Outlet />,
            children: [
                { index: true, element: <PickDeck /> },
                { path: ':deckId', element: <AboutDeck /> }
            ]
        },
        {
            path: 'dashboard',
            element: <Dashboard />
        },
        {
            path: 'spread',
            element: <Outlet />,
            children: [
                { index: true, element: <PickDeck /> },
                { path: ':spreadType', element: <AboutSpread /> },
                { path: ':spreadType/card', element: <PickCard /> },
                { path: ':spreadType/intention', element: <IntentionSpread /> },
                { path: ':spreadType/about', element: <AboutSpread showBack={true} /> },
                { path: ':spreadType/reflection', element: <Reflection /> }
            ]
        },
        {
            path: 'card/:deckId/:cardId',
            element: <AboutCard />
        }
    ]
}];

export default routes;