import React, {useEffect, useState} from "react";
import {Link, useParams} from "react-router-dom";
import {getCardById, getCardsByType} from "../helpers/card";
import {useCollapse} from 'react-collapsed';
import {useSpreadContext} from "../contexts/SpreadContext";
import {formatDate} from "../helpers/date";
import dayjs from "dayjs";

const AboutCard = () => {
    const params = useParams();
    const [currentCard, setCurrentCard] = useState({})
    const [cardDates, setCardDates] = useState([])
    const { getCollapseProps, getToggleProps, isKeywordExpanded } = useCollapse()
    const { spreadList, spread } = useSpreadContext()

    useEffect(() => {        
        if (params.cardId && params.deckId) {
            setCurrentCard(() => getCardById(params.cardId, params.deckId))
        }
    }, [params]);

    useEffect(() => {
        if (spreadList && currentCard) {
            setCardDates(() => {
                return spreadList.filter(_ => {
                    const isSameDeck = _.deck.id === spread.deck.id
                    const isMatchedType = Object.values(_.card).findIndex(card => card.type === currentCard.type) !== -1

                    return isSameDeck && isMatchedType
                }).map(_ => _.date)
            })
        }
    }, [spreadList, currentCard]);

    if(!currentCard.id){
        return null;
    }

    return (    
        <div className="main-menu bg-black text-white">
            <div className="p-4 pb-0">
                <div className="grid gap-8 px-4 pb-8">
                    <h3 className="text-2xl uppercase text-center">
                        {currentCard.name}
                    </h3>
                    <img src={currentCard.image} className='object-cover w-full h-full rounded-[10px]' alt='Back face card'/>
                    <div>
                        <div {...getToggleProps()}>
                            ↦ {currentCard.name} keywords {isKeywordExpanded ? '(-)' : '(+)'}
                        </div>
                        <div {...getCollapseProps()} className="m-3">
                            <div>Upright keywords: {currentCard.upright.keywords}.</div>
                            <div className="mt-2">Reversed keywords: {currentCard.reversed.keywords}.</div>
                        </div>
                    </div>

                    <div>{currentCard.name} description: </div>
                    <div>{currentCard.description}</div>

                    <div>{currentCard.name} upright meaning: </div>
                    <div>{currentCard.upright.content}</div>

                    <div>{currentCard.name} upright career meaning: </div>
                    <div>Keywords: {currentCard.upright.meanings[0].keywords}</div>
                    <div>{currentCard.upright.meanings[0].content}</div>
                    
                    <div>{currentCard.name} upright love meaning: </div>
                    <div>Keywords: {currentCard.upright.meanings[1].keywords}</div>
                    <div>{currentCard.upright.meanings[1].content}</div>

                    <div>{currentCard.name} upright finance meaning: </div>
                    <div>Keywords: {currentCard.upright.meanings[2].keywords}</div>
                    <div>{currentCard.upright.meanings[2].content}</div>

                    <div>{currentCard.name} upright reversed meaning: </div>
                    <div>{currentCard.reversed.content}</div>
                    <Link to={`/deck/${spread.deck.id}`} className="block text-center underline underline-offset-1 font-medium text-[#FEB710] font-futura">About this deck</Link>
                    <div className="rounded-[10px] bg-[#393939] p-4 text-white font-medium uppercase flex flex-col space-y-4 justify-between items-center">
                        <h3>Card Count</h3>
                        <Link to={`/deck/${spread.deck.id}`} className="text-[100px] text-[#FEB710] leading-none">{cardDates.length}</Link>
                        {cardDates.length > 0 && (
                            <>
                                <h3>Dates</h3>
                                <div className="grid grid-cols-3 gap-x-4 text-[10px] text-left w-full">
                                    {
                                        cardDates.map((date) => (
                                            <div key={date}>{formatDate(dayjs.unix(date.seconds))}</div>
                                        ))
                                    }
                                </div>
                            </>
                        )}

                    </div>
                    <div>
                        <Link to={-1} className="btn btn-outlined bg-transparent">Back</Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AboutCard;