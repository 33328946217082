// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore, collection, getDocs, query, where } from 'firebase/firestore';
import { doc, setDoc, onSnapshot } from "firebase/firestore";
import {useEffect, useState} from "react";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyBihOMUILkteXyHuLC2wk3SYy85UPe3XGg",
    authDomain: "tarot-b316e.firebaseapp.com",
    projectId: "tarot-b316e",
    storageBucket: "tarot-b316e.appspot.com",
    messagingSenderId: "577520710005",
    appId: "1:577520710005:web:1f62de1ee9f2c2afb3633c",
    measurementId: "G-GKT6K7XYEV"
};

// Initialize Firebase
export const firebaseApp = initializeApp(firebaseConfig);
export const firestore = getFirestore(firebaseApp);

export async function getSpreadList({ id: userId }) {
    try {
        const spreadCollection = collection(firestore, 'spreads');
        const spreadQuery = query(spreadCollection, where('userId', '==', userId))
        const spreadSnapshot = await getDocs(spreadQuery)
        return spreadSnapshot.docs.map(spread => spread.data())
    } catch {
        return []
    }
}

export async function saveSpread(spread) {
    const spreadRef = doc(firestore, 'spreads', spread.id)
    await setDoc(spreadRef, spread)
}

export function useSpreadList({ id: userId }) {
    const [spreadList, setSpreadList] = useState([])
    const spreadCollection = collection(firestore, 'spreads');

    const fetchSpreads = () => {
        const spreadQuery = query(spreadCollection, where('userId', '==', userId))

        getDocs(spreadQuery).then(snapshot => {
            setSpreadList(() => snapshot.docs.map(spread => spread.data()))
        })
    }


    useEffect(() => {
        userId && fetchSpreads()
    }, [userId]);

    return {
        spreadList,
        fetchSpreads
    }
}