import React, {useEffect, useState} from 'react';

const MAX_INTENTION_LENGTH = 200;

const IntentionBox = ({ onChange, value, title, placeholder }) => {
    const [intention, setIntention] = useState(value || '')
    const [remainLength, setRemainLength]  = useState(MAX_INTENTION_LENGTH)

    useEffect(() => {
        value && setIntention(value)
    }, [value]);

    useEffect(() => {
        setRemainLength(() => MAX_INTENTION_LENGTH - intention.length)
        onChange && onChange(intention)
    }, [intention, onChange]);

    return (
        <div className='rounded-[10px] p-4 bg-[#393939]'>
            <div className='font-medium flex items-center justify-between'>
                <h3>{title}</h3>
                <span className='text-xs'>{remainLength}</span>
            </div>
            <div className='mt-4'>
                <textarea
                    value={intention}
                    onChange={(e) => setIntention(e.target.value)}
                    placeholder={placeholder}
                    rows={6}
                    className={'resize-none border w-full text-sm border-[#606060] rounded-[10px] bg-[#1c1c1c] p-4 placeholder:text-[#797979] placeholder:text-sm placeholder:font-[300]'}
                />
            </div>
        </div>
    );
};

export default IntentionBox;