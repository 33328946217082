import Card from './Card';
import {Link, useNavigate, useParams} from 'react-router-dom';
import {useSpreadContext} from "../../contexts/SpreadContext";
import {getSpreadName, getSpreadDescription} from "../../helpers/spread";

export const SpreadDetail = ({ type, onRequestClose }) => {
    const { spread } = useSpreadContext();

    return (
        <div className="main-menu bg-black text-white">
            <div className="grid gap-8 px-4 pb-8">
                <h3 className="text-2xl uppercase text-center">About {getSpreadName(type)} spread</h3>
                {
                    type === 'three' ? (
                        <div className='mx-auto relative h-[235px]'>
                            <div className='absolute right-1/2 top-0 -rotate-6 translate-y-[6px]'>
                                <Card backface={spread.deck.background} width={"138px"} height={"235px"} />
                            </div>
                            <div className='absolute right-1/2 top-0 translate-x-1/2'>
                                <Card backface={spread.deck.background} width={"138px"} height={"235px"} />
                            </div>
                            <div className='absolute left-1/2 top-0 rotate-6 translate-y-[6px]'>
                                <Card backface={spread.deck.background} width={"138px"} height={"235px"} />
                            </div>
                        </div>
                    ) : (
                        <div className='mx-auto'>
                            <Card backface={spread.deck.background} width={"138px"} height={"235px"} />
                        </div>
                    )
                }
                <div className='font-futura font-medium'>
                    {getSpreadDescription(type)}
                </div>
                <button onClick={() => onRequestClose && onRequestClose()} className='btn btn-primary font-paytoneOne mt-8 w-full mb-4'>Back</button>
            </div>
        </div>
    );
};