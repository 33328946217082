import {useParams, useSearchParams} from "react-router-dom";
import {collection, getDocs, query, where} from "firebase/firestore";
import {firestore} from "../../helpers/firebase";
import {useQuery} from "@tanstack/react-query";
import {useAppContext} from "../../contexts/AppContext";
import {useMemo, useState} from "react";
import ReflectionTile from "./ReflectionTile";

const fetchSpreads = (userId, type, deck) => {
    const spreadCollection = collection(firestore, 'spreads');
    const spreadQuery = query(spreadCollection,
        where('userId', '==', userId),
        where('type', '==', type),
        where('deck.id', '==', deck)
    )
    return getDocs(spreadQuery).then(snapshot => snapshot.docs.map(spread => spread.data()))
}

export function Reflections({ type, itemsPerPage, deck }) {
    const { user } = useAppContext()
    const { data, isFetching } = useQuery({
        queryKey: ['spreads', type],
        queryFn: () => fetchSpreads(user.firebaseId, type, deck),
        enabled: !!user
    })

    const [maxVisible, setMaxVisible] = useState(itemsPerPage || 10)
    const spreads = useMemo(() => (data || []).slice(0, maxVisible), [data, maxVisible])
    const hasMore = useMemo(() => spreads.length < (data || []).length, [spreads, data, maxVisible])

    if (isFetching) {
        return <>Loading...</>
    }

    return (
        <>
            <div className="grid gap-2">
                {spreads.map((spread) => (<ReflectionTile key={spread.id} spread={spread} type={type}/>))}
                {spreads.length === 0 && (<p className={'text-center'}>It doesn't have any reflection yet.</p>)}
            </div>

            {hasMore && (
                <button type={'button'}
                        onClick={() => setMaxVisible((prev) => Math.min(prev + itemsPerPage, (data || []).length))}
                        className="block border-0 p-6 text-[#FEB710] underline mx-auto py-2">
                    Show more
                </button>
            )}
        </>
    )
}