import React from 'react';
import Card from './Card';
import { Link } from 'react-router-dom';
import {useSpreadContext} from "../../contexts/SpreadContext";

const CardPicker = (props) => {
    const { makeSpread } = useSpreadContext()

    return (
        <Link to={`../spread/${props.type}`} onClick={() => makeSpread(props.type) } className='relative h-[171px] flex flex-col justify-end bg-aura'>
            {props.count !== 1 ? <div className='absolute right-1/2 top-0 -rotate-12 translate-x-[20px] translate-y-[6px]'>
                <Card backface={props.backface} width={'89px'} height="151px" />
            </div> : ''}
            <div className='absolute right-1/2 top-0 translate-x-1/2'>
                <Card backface={props.backface} width="89px" height="151px"/>
            </div>
            {props.count !== 1 ? <div className='absolute left-1/2 top-0 rotate-12 translate-x-[-20px] translate-y-[6px]'>
                <Card backface={props.backface} width="89px" height="151px" />
            </div> : ''}
            <div className='p-[10px_59px] border border-[#FEB710] rounded-[100px] shadow-[0px_1px_3px_0px_rgb(0,0,0,0.35),0px_0px_20px_0px_rgba(255,179,0,0.50)] relative z-[1] bg-[#393939] text-white text-xl text-center font-paytoneOne'>
                {props.title}
            </div>
        </Link>
    );
};

export default CardPicker;