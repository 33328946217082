import {useNavigate} from "react-router-dom";
import { Swiper, SwiperSlide } from 'swiper/react';
import {useEffect, useState} from "react";
import {useSpreadContext} from "../contexts/SpreadContext";

export default function PickDeck() {
    const [activeIndex, setActiveIndex] = useState(null)
    const [activeDeck, setActiveDeck] = useState({})

    const { decks, spread, setDeck } = useSpreadContext()
    const navigate = useNavigate()

    useEffect(() => {
        if (spread.deck?.id) {
            setActiveIndex(() => decks.findIndex(deck => deck.id === spread.deck.id))
            setActiveDeck(() => decks.find(deck => deck.id === spread.deck.id))
        } else {
            setActiveIndex(0)
            setActiveDeck(() => ({ ...decks[0] }))
        }
    }, [decks, spread]);

    const selectDeck = () => {
        setDeck(activeDeck)
        navigate('../dashboard')
    }

    if (activeIndex === null) {
        return;
    }

    return (
        <div className="pick-deck bg-black pb-6 text-white">
            <h3 className="text-2xl uppercase text-center">Pick Your Deck</h3>
            <div className="py-8">
                <Swiper
                    initialSlide={activeIndex}
                    spaceBetween={20}
                    slidesPerView={'auto'}
                    centeredSlides={true}
                    autoHeight={true}
                    onActiveIndexChange={({ activeIndex }) => setDeck(decks[activeIndex])}>
                    {decks.map(deck => (
                        <SwiperSlide key={deck.id}>
                            <img src={deck.background} alt={deck.description} className="object-contain w-full h-full rounded-md" />
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
            <h3 className="text-[26px] capitalize text-center">{activeDeck?.name}</h3>
            <p className="text-center mt-8 px-4">{activeDeck?.description}</p>
            <button onClick={() => selectDeck()} className="btn btn-primary mt-8 font-paytoneOne w-full">Use this deck</button>
        </div>
    );
}
