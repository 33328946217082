import IntentionBox from '../components/elements/IntentionBox';
import {Link, useNavigate, useParams} from 'react-router-dom';
import React, {useCallback, useEffect, useState} from "react";
import {useSpreadContext} from "../contexts/SpreadContext";
import {getSpreadName, getSpreadDescription} from "../helpers/spread";
import {SpreadDetail} from "../components/elements/SpreadDetail";
import ReactSlidingPane from "react-sliding-pane";

const ThreeIntentionBoxes = ({ onChange }) => {
    const [past, setPast] = useState('')
    const [present, setPresent] = useState('')
    const [future, setFuture] = useState('')

    const handleChangeCallback = useCallback(() => {
        onChange && onChange({
            past,
            present,
            future
        })
    }, [past, present, future, onChange])

    useEffect(() => {
        handleChangeCallback()
    }, [past, present, future]);

    return (
        <div className='grid gap-2'>
            <IntentionBox onChange={setPast} value={past} name={'past'} title={'My Intention'} placeholder={'Past?'} />
            <IntentionBox onChange={setPresent} value={present} name={'present'} title={'My Intention'} placeholder={'Present?'} />
            <IntentionBox onChange={setFuture} value={future} name={'future'} title={'My Intention'} placeholder={'Future?'} />
        </div>
    )
}

export default function IntentionSpread() {
    const { spreadType } = useParams();
    const { commitIntentions, setCard } = useSpreadContext()
    const [isDisabled, setDisabled] = useState(true)
    const [intentions, setIntentions] = useState(null)
    const [aboutSpread, setAboutSpread] = useState()

    const navigate = useNavigate()
    const handleIntentionChange = (value) => {
        setIntentions(value)
    }

    const showSpread = () => {
        commitIntentions(intentions)
        setCard(null)

        navigate('../card', {
            relative: 'path'
        })
    }

    useEffect(() => {
        intentions && setDisabled(() => {
            if (intentions.length === 0) {
                return true
            } else if (typeof intentions == 'string') {
                return intentions.length === 0
            }

            return Object.values(intentions)
                .filter(intentionItem => intentionItem.length > 0)
                .length !== 3
        })
    }, [intentions]);

    return (
        <>
            <div className="main-menu bg-black text-white">
                <div className='p-4 pb-0'>
                    <div className="grid gap-8 px-4 pb-8">
                        <h3 className="text-2xl uppercase text-center">My Intention</h3>
                        <div className='font-futura font-medium'>
                            {getSpreadDescription(spreadType)}
                        </div>
                        {spreadType === 'single' &&
                            <IntentionBox value={intentions} onChange={handleIntentionChange} name={'single'}
                                          title={'My Intention'} placeholder={'Will I get a raise?'}/>}
                        {spreadType === 'three' &&
                            <ThreeIntentionBoxes value={intentions} onChange={handleIntentionChange}/>}

                        <button type="button" onClick={() => setAboutSpread(() => true)}
                                className="block mx-auto border-0 text-[#FEB710] underline mt-4 text-center block">
                            About {getSpreadName(spreadType)} Spread
                        </button>
                        <div className='grid gap-4 mt-8'>
                            <button onClick={showSpread} disabled={isDisabled}
                                    className='btn btn-primary w-full disabled:opacity-40'>Show Spread
                            </button>
                            <Link to={'../card'} relative={'path'} className="btn btn-outlined w-full bg-transparent">
                                skip
                            </Link>
                            <Link to={'/deck'} className="btn btn-outlined w-full bg-transparent">
                                Change Deck
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <ReactSlidingPane
                hideHeader={true}
                isOpen={!!aboutSpread}
                onRequestClose={() => setAboutSpread(() => null)}
                className="p-0 bg-[#212121]"
                width={'100%'}>
                <SpreadDetail type={spreadType} onRequestClose={() => setAboutSpread(() => null)} />
            </ReactSlidingPane>
        </>
    );
}
