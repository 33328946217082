import React, {useEffect, useState} from 'react';
import {Link, useParams} from 'react-router-dom';
import {getDeckById} from "../helpers/card";

const AboutDeck = () => {
    const { deckId } = useParams()
    const [deck, setDeck] = useState()

    useEffect(() => {
        if (deckId) {
            setDeck(() => getDeckById(deckId))
        }
    }, [deckId]);

    if (!deck) {
        return;
    }

    return (
        <div className="main-menu bg-black text-white">
            <div className="p-4 pb-0">
                <div className="grid gap-8 px-4 pb-8">
                    <h3 className="text-2xl uppercase text-center">{deck.name}</h3>
                    <img src={deck.background} alt={deck.name} className={'mx-auto shadow-lg rounded-[10px]'} />
                    <div className="font-futura font-medium">{deck.description}</div>
                    <div>
                        <Link to={-1} className="btn btn-primary">Back</Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AboutDeck;