import { Link } from "react-router-dom";

export default function Spash() {
    return (
        <div className="intro text-center relative">
            <img src="/imgs/bg-splash.jpg" className="mx-auto" alt={'Tarot'} />
            <div className="intro-content">
                <h3 className="title">Daily Tarot</h3>
                <p className="mb-[66px] text-[18px]">This is a self-guided tarot experience for users of all knowledge and skill levels. Seek answers, gain insight, and explore new possibilities.</p>
                <Link to="/deck" className="btn btn-outlined glowing">Get Started</Link>
            </div>
        </div>
    )
}