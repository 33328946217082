import React from 'react';
import {Link} from "react-router-dom";

const CardInput = (props) => {
    const image = props.image || '/imgs/BackFaceCard.png';
    const stats = props.stats || { total: 0 }

    const getStat = (id) => {
        if (stats.total > 0 && stats[id] !== undefined) {
            return `${(stats[id] / stats.total) * 100}%`;
        }

        return 0
    }

    return (        
        <Link to={`/card/${props.deckId}/${props.cardId}`}>
            <img src={image} className='object-cover rounded-md shadow' alt=''/>
            <div className={'bg-[#2A2A2A] mt-1 rounded-full'}>
                <div className='rounded-full bg-[#FEB710] w-full h-[5px]' style={{ width: getStat(props.cardId) }}></div>
            </div>
        </Link>
    );
};

export default CardInput;