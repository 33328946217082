import { createContext, PropsWithChildren, useContext, useEffect, useState } from 'react'
import { jwtDecode } from 'jwt-decode'
import useMessage from '@rottitime/react-hook-message-event'
import { Link, useLocation } from 'react-router-dom'
import { useCookie } from '@reactuses/core'

const initialValue = {
  user: {
    id: -1,
    name: 'Guest',
    avatar: '',
    firebaseId: ''
  },
  token: '',
  url: window.location.toString()
}

export const AppContext = createContext(initialValue)
export const useAppContext = () => useContext(AppContext)

export const AppContextProvider = ({ children }) => {
  const [data, setData] = useState(initialValue)
  const [cookieValue] = useCookie('Firebase')

  useEffect(() => {
    if (cookieValue) {
      const decodedPayload = jwtDecode(cookieValue)
      setData((prev) => ({
        ...prev,
        user: {
          ...prev.user,
          id: decodedPayload.userId,
          name: decodedPayload.userName,
          firebaseId: decodedPayload.firebaseUserId
        }
      }))
    }
  }, [cookieValue])

  useMessage('mtb/context', (send, payload) => {
    const { url, token, user } = payload;
    const decodedPayload = jwtDecode(token)

    console.log('mtb/context', user.id, decodedPayload)

    setData((prev) => ({
      ...prev,
      url,
      user: {
        ...prev.user,
        ...user,
        id: decodedPayload.userId,
        name: decodedPayload.userName
      }
    }))
  })

  if (data.user.id === -1) {
    return <></>
  }

  return (
    <AppContext.Provider value={data}>
      {children}
    </AppContext.Provider>
  )
}
