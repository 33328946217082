import {useNavigate} from "react-router-dom";
import {useSpreadContext} from "../../contexts/SpreadContext";
import {formatDate} from "../../helpers/date";
import dayjs from "dayjs";

const ReflectionTile = ({ spread, type }) => {
    const { setSpread } = useSpreadContext()
    const navigate = useNavigate()

    const openReflection = () => {
        setSpread(spread)
        navigate(`/spread/${type}/reflection`)
    }

    return (
        <button onClick={openReflection} className="flex items-center space-x-[6px] p-3 rounded-[10px] border border-[#FFFFFF4D] text-left">
            <div>
                {
                    type === 'daily' ?
                    (
                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="31" viewBox="0 0 30 31" fill="none">
                            <path fillRule="evenodd" clipRule="evenodd" d="M8.99951 2.68225C7.89494 2.68225 6.99951 3.57768 6.99951 4.68225V26.6823C6.99951 27.7868 7.89494 28.6823 8.99951 28.6823H21.0002C22.1047 28.6823 23.0002 27.7868 23.0002 26.6823V4.68225C23.0002 3.57768 22.1047 2.68225 21.0002 2.68225H8.99951ZM16.6928 13.3523L15 9.68225L13.3072 13.3523L9.29366 13.8281L12.261 16.5722L11.4733 20.5364L15 18.5623L18.5267 20.5364L17.739 16.5722L20.7063 13.8281L16.6928 13.3523Z" fill="white" />
                        </svg>
                    ) : type === 'three' ?
                    (
                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                            <path fillRule="evenodd" clipRule="evenodd" d="M9.12211 25H9.00006C8.44777 25 8.00006 24.5523 8.00006 24V4C8.00006 3.44772 8.44777 3 9.00006 3H11.4404C11.5121 2.61363 11.6928 2.27065 11.9467 2H9.00006C7.89549 2 7.00006 2.89543 7.00006 4V24C7.00006 25.1046 7.89549 26 9.00006 26H9.29377C9.15547 25.6966 9.09135 25.3547 9.12211 25ZM21.9828 2.25767C21.6925 2.09362 21.3572 2 21.0001 2H19.5312L21.9828 2.25767ZM12.2997 4.21878C12.4151 3.12026 13.3993 2.32333 14.4978 2.43879L26.432 3.69313C27.5306 3.80859 28.3275 4.79272 28.212 5.89124L26.1215 25.7817C26.006 26.8802 25.0219 27.6771 23.9233 27.5617L11.9891 26.3073C10.8906 26.1919 10.0936 25.2077 10.2091 24.1092L12.2997 4.21878ZM3.9891 3.6922L6 3.48084V4.48635L4.09363 4.68672C3.54437 4.74445 3.14591 5.23651 3.20364 5.78577L5.29421 25.6762C5.35194 26.2255 5.844 26.6239 6.39326 26.5662L6.71464 26.5324C7.06224 26.8243 7.51059 27.0001 8 27.0001H11.8318L6.49779 27.5607C5.39927 27.6762 4.41514 26.8793 4.29969 25.7807L2.20912 5.8903C2.09366 4.79178 2.89058 3.80766 3.9891 3.6922Z" fill="white"/>
                        </svg>
                    ) : type === 'single' ?
                    (
                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                            <path d="M7 4C7 2.89543 7.89543 2 9 2H21C22.1046 2 23 2.89543 23 4V26C23 27.1046 22.1046 28 21 28H9C7.89543 28 7 27.1046 7 26V4Z" fill="white"/>
                        </svg>
                    ) : (
                        <div></div>
                    )
                }
            </div>
            <div className="grow">
                <div className="flex items-center justify-between text-xs">
                    <h3 className="font-bold text-white uppercase">
                        { type === 'single' ? 'Single Card' : type === 'three' ? '3 Cards Spread' : type === 'daily' ? 'Card of the day' : '' }
                    </h3>
                    <span className="text-[#FFFFFF80] font-medium">{formatDate(dayjs.unix(spread.date.seconds))}</span>
                </div>
                <p className="mt-0 text-[#FFFFFF80] line-clamp-2 text-sm font-medium">{spread.reflections}</p>
            </div>
        </button>
    );
};

export default ReflectionTile;
