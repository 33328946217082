import Card from '../components/elements/Card';
import {Link, useNavigate, useParams} from 'react-router-dom';
import {useSpreadContext} from "../contexts/SpreadContext";
import {getSpreadName, getSpreadDescription} from "../helpers/spread";

const AboutSpread = (props) => {
    const { spreadType } = useParams();
    const { spread } = useSpreadContext();
    const navigate = useNavigate();
    
    if (spreadType === null) {
        return;
    }

    return (
        <div className="main-menu bg-black text-white">
            <div className='p-4 pb-0'>
                <div className="grid gap-8 px-4 pb-8">
                    <h3 className="text-2xl uppercase text-center">About {getSpreadName(spreadType)} spread</h3>
                    {
                        spreadType === 'three' ? (
                            <div className='mx-auto relative h-[235px]'>
                                <div className='absolute right-1/2 top-0 -rotate-6 translate-y-[6px]'>
                                    <Card backface={spread.deck.background} width={"138px"} height={"235px"} />
                                </div>
                                <div className='absolute right-1/2 top-0 translate-x-1/2'>
                                    <Card backface={spread.deck.background} width={"138px"} height={"235px"} />
                                </div>
                                <div className='absolute left-1/2 top-0 rotate-6 translate-y-[6px]'>
                                    <Card backface={spread.deck.background} width={"138px"} height={"235px"} />
                                </div>
                            </div>
                        ) : (
                            <div className='mx-auto'>
                                <Card backface={spread.deck.background} width={"138px"} height={"235px"} />
                            </div>
                        )
                    }
                    <div className='font-futura font-medium'>
                        {getSpreadDescription(spreadType)}                        
                    </div>
                    { props.showBack && <button onClick={() => { navigate(-1) }} className='btn btn-primary font-paytoneOne mt-8 w-full mb-4'>Back</button> }
                    { !props.showBack && (
                        <div>
                            <Link to={spreadType === 'daily' ? 'card' : 'intention'} className='btn btn-primary font-paytoneOne mt-8 w-full mb-4'>
                                NEXT
                            </Link>
                            <Link to={"/deck"} className="p-[10px_59px] border border-[#797979] rounded-[100px] shadow-[0px_1px_3px_0px_rgba(0,0,0,0.35)] relative z-[1] bg-[#393939] text-white text-xl block text-center">
                                Change Deck
                            </Link>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default AboutSpread;