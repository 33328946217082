import React, {useEffect, useState} from 'react';
import IntentionBox from '../components/elements/IntentionBox';
import {Link, useNavigate, useParams} from 'react-router-dom';
import IntentionTile from '../components/elements/IntentionTile';
import ModalShare from "../components/elements/ModalShare";
import {FacebookShareButton, RedditShareButton, TwitterShareButton} from "react-share";
import {useSpreadContext} from "../contexts/SpreadContext";
import {getSpreadName} from "../helpers/spread";
import {toast} from "react-toastify";
import classNames from "classnames";

const FlippableCard = ({ deck, card, flipped }) => {
    const [flip, setFlip] = useState(flipped);

    return (
        <div className={`card mx-auto aspect-[138/235] max-w-[140px] ${flip ? "is-flipped" : ""}`}>
            <div className={`w-[138px] h-[235px] aspect-[138/235] card-face card-face-front`} >
                <img onClick={() =>  setFlip((!flip))} src={deck.background} className='object-cover w-full h-full rounded-[10px]' alt='Back face card'/>
            </div>
            <Link to={`/card/${deck.id}/${card.id}`} className={`w-[138px] h-[235px] aspect-[138/235] card-face card-face-back`}>
                <img src={card.image} className='object-cover w-full h-full rounded-[10px]' alt='Back face card'/>
            </Link>
        </div>
    )
}

const Reflection = () => {
    const { spreadType } = useParams()
    const { spread, commitReflections } = useSpreadContext()

    const [isModalVisible, setModalVisible] = useState(false);
    const [intentions, setIntentions] = useState(null)
    const [reflection, setReflection] = useState(null)
    const [shareUrl, setShareUrl] = useState(null)
    const [cards, setCards] = useState([])
    const [isSaving, setSaving] = useState(false)

    const navigate = useNavigate()

    useEffect(() => {
        if (spread.intentions) {
            setIntentions(() => spread.intentions)
            setShareUrl(() => `${window.location.origin}/card/${spread.deck.id}/${Object.values(spread.card).map(card => card.id).join(',')}`)
            setCards(() => Object.values(spread.card))
        }
    }, [spread]);

    const isPlainObject = value => value?.constructor === Object;
    const saveReflection = async () => {
        setSaving(true)
        await commitReflections(reflection)
        navigate('/dashboard')
        setSaving(false)
        toast('Best wishes! You\'ve successfully saved your reflection.', {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "dark",
        })
    }

    if (cards.length === 0) {
        return;
    }

    return (
        <div className="main-menu bg-black text-white">
            <div className='p-4 pb-0'>
                <div className="grid gap-8 px-4 pb-8">
                    <h3 className="text-2xl uppercase text-center">{getSpreadName(spreadType)} spread</h3>
                    <div className={'flex gap-2.5'}>
                        {cards.map((card) => (
                            <FlippableCard key={card.id} deck={spread.deck} card={card} flipped={true} />
                        ))}
                    </div>
                    <p className='text-[#ffffff80] text-center font-futura'>Tab to view card meaning</p>
                    {
                        isPlainObject(intentions)
                            ? (
                                <div className='grid gap-2'>
                                    <IntentionTile card={cards[0]} userIntention={intentions.past} title={"Card 1"} />
                                    <IntentionTile card={cards[1]} userIntention={intentions.present} title={"Card 2"} />
                                    <IntentionTile card={cards[2]} userIntention={intentions.future} title={"Card 3"} />
                                </div>
                            ) : (
                                <IntentionTile card={cards[0]} userIntention={intentions} />
                            )
                    }
                    <IntentionBox value={spread.reflections} onChange={setReflection} name={'reflection'} title={"My Reflection"} />
                    <div>
                        <button onClick={() => setModalVisible(true)} className='btn btn-primary font-paytoneOne mt-8 w-full mb-4'>
                            share
                        </button>
                        <button onClick={saveReflection} className="w-full p-[10px_59px] border border-[#797979] rounded-[100px] shadow-[0px_1px_3px_0px_rgba(0,0,0,0.35)] relative z-[1] bg-[#393939] font-paytoneOne text-white text-xl block text-center">
                            Save & Close
                        </button>
                    </div>
                </div>
            </div>

            <ModalShare isOpen={isModalVisible} closeModal={() => setModalVisible(false)}>
                <div className='p-6'>
                    <div className='uppercase font-trykker text-center text-[40px]'>daily tarot</div>
                    <div className={classNames('relative flex justify-center items-center gap-2.5', { 'flex-col gap-2.5': cards.length > 1 })}>
                        <img src={cards.length === 1 ? '/imgs/decor.png' : '/imgs/decor-horz.png'} className={classNames('opacity-10', { 'w-[60px]': cards.length === 1, 'h-[60px]': cards.length > 1 })} alt="" />
                        <div className={classNames("flex gap-2.5")}>
                            {cards.map(card => (
                                <div key={card.id} className='aspect-[138/237] max-w-[138px] w-full'>
                                    <img src={card.image} className='w-full object-cover' alt="" />
                                </div>
                            ))}
                        </div>
                        <img src={cards.length === 1 ? '/imgs/decor.png' : '/imgs/decor-horz.png'} className={classNames('opacity-10 rotate-180', { 'w-[60px]': cards.length === 1, 'h-[60px]': cards.length > 1 })} alt="" />
                    </div>
                    <div className='uppercase font-futura text-center text-2xl'>My {getSpreadName(spreadType)} spread</div>
                    <div className='flex items-center justify-center space-x-4 mt-3'>
                        <FacebookShareButton url={shareUrl} className='w-8 aspect-square flex-none'>
                            <img src="/imgs/facebook.png" alt="" />
                        </FacebookShareButton>
                        <TwitterShareButton url={shareUrl} className='w-8 aspect-square flex-none'>
                            <img src="/imgs/twitter.png" alt="" />
                        </TwitterShareButton>
                        <RedditShareButton url={shareUrl} className='w-8 aspect-square flex-none'>
                            <img src="/imgs/reddit.png" alt="" />
                        </RedditShareButton>
                    </div>
                </div>
            </ModalShare>
        </div>
    );
};

export default Reflection;